<template>
  <div class="examination">
    <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
    <div class="examinationdiv">
        <!-- <div class="examinationdiv_tips">
            <p>您上一次选择：</p>
            截止今日，xxx学员累计做<span>89234</span>道题！
            <p class="examinationdiv_tips_button">中级会计实务</p>
        </div> -->
        <div class="examinationdiv_calss" v-if="first_List.length>0">
            <div class="examinationdiv_calss_subject" v-for="(item,index) in first_List" :key="index">
                <img :src="item.firstIconUrl">
                <div>
                    <p class="examinationdiv_calss_subject_title">{{item.firstName}}<span></span></p>
                    <!-- <p class="examinationdiv_calss_subject_center">高频考点、历年真题高频考点、高频考点、历年真题高频考点、高频考点、历年真题高频考点</p> -->
                    <p class="examinationdiv_calss_subject_button" >
                        <!-- v-if="item.secondList.length>0" -->
                        <el-button round v-for="(firstitem,key) in item.secondList" :key="key" @click="toDetail(item,key)">{{firstitem.secondName}}</el-button>
                    </p>
                </div>
            </div>
        </div>
        <div class="examinationdiv_empty" v-if="first_List.length<=0">
            <div class="examinationdiv_empty_img">
                <img src="@/assets/exam_empty.png" alt="">
            </div>
            <div class="examinationdiv_empty_text">暂无题库内容<br>换个时间试试吧</div>
        </div>
        <!-- 分页 -->
        <!-- <div class="examinationdiv_pagination" v-if="testList.length">
            <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="20"
            layout="total, prev, pager, next"
            :total="count">
            </el-pagination>
        </div> -->
    </div>
    <t_footer></t_footer>
  </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { firstOrSecondList,secondOrList } from "@/api/exam";
export default {
  name: 'examination',
  components: {
    t_header,
    t_footer,
  },
  data(){
    return{
        first_List:[
            {
                coverPic:require('@/assets/home/class_banner.png'),
            },
            {
                coverPic:require('@/assets/home/class_banner.png'),
            }
        ],
        page:0,
        count:20,
        isType:'1',
        isSort:false,
        todos:false,
        isUserInfo:false,
    }
  },
  mounted(){
    this.firstOrSecondList()
  },
  watch:{
    $route(to,from){
        console.log(from.path,to.path);//从哪来  到哪去
    },
  },
  methods:{
    // 父传子
    getTodos(e){
        this.isUserInfo=e
    },
    firstOrSecondList(){
        firstOrSecondList().then(res=>{
            if(res.data.code==0){
                this.first_List=res.data.data
            }
            else{
                this.$message.error('提示');
            }
        })
    },
    // 分页
    handleCurrentChange(val){
        this.page = val-1
        this.xxx()
    },
    // 去详情
    toDetail(item,index){
        const userInfo = localStorage.getItem("setuserInfo");
        if(userInfo==null){
            this.isUserInfo=true
            return false
        }


        this.$router.push({
            path: '/examcenter',
            query: {firstId:item.firstId,secondId:item.secondList[index].secondId}
        });
    }
  }
}
</script>
